/* eslint-disable no-unused-vars */
import Vue from 'vue';
import Vuex from 'vuex';
//import pathify from 'vuex-pathify'
import BFC from './modules/bfc.module';


Vue.use(Vuex)

export default new Vuex.Store({
  //plugins: [ pathify.plugin ], // activate plugin
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    BFC
  },
})
