<template>
  <!-- App.vue -->
  <v-app>
    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <!-- If using vue-router -->
        <router-view>
          
           

        </router-view>
     
      </v-container>
    </v-main>
    <p class="version">Version {{ version }}</p>
  </v-app>
</template>

<script>
//import AppSideBar from "./backend/components/menu/sideBarMenu"
//import AppTopBar from "./backend/components/menu/topBarMenu"

export default {
  name: "app",
  components: {
    // "app-sidebar": AppSideBar,
    // "app-topbar": AppTopBar,
  },
  data() {
    return {
      //show: false,
      version: process.env.VUE_APP_VERSION,
    };
  },
};
</script>

<style lang="css">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

.sidebar {
  background-color: #42b983;
}

p,
li,
dd,
dt {
  max-width: 50ch;
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.5;
}

small {
  font-size: max(0.5em, 0.8rem);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  line-height: 1.25;
}

h1,
h2,
h3 {
  font-weight: lighter;
  letter-spacing: 0.05em;
}

h1 {
  font-size: 4rem;
  max-width: 17ch;
}

h2 {
  font-size: 3rem;
  max-width: 25ch;
}

h3 {
  font-size: 2rem;
  max-width: 30ch;
}

h4 {
  font-size: 1.75rem;
  max-width: 30ch;
}
h5 {
  font-size: 1.5rem;
  max-width: 35ch;
}
h6 {
  font-size: 1.25rem;
  max-width: 40ch;
}

ol,
ul,
dl {
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
}

li,
dd {
  max-width: 40ch;
}

li::marker,
dd::marker {
  color: hsl(200, 50%, 50%);
}

dt {
  font-weight: bolder;
}

dt:not(:first-of-type) {
  margin-top: 2ch;
}

p.version {
  margin-left: auto;
  margin-right: auto;
  font-size: 0.6rem;
}
</style>
