/* eslint-disable no-unused-vars */
import axios  from "axios"
import _ from "lodash"


const state = {
    config: {
      custId: process.env.VUE_APP_OGF_CUST_ID,
      url: process.env.VUE_APP_LOYALTY_API_URI,
    },
    doctor: {
        auth: {},
        cases: {},
        info: {},
        draft: {}
    },
    admin: {
        auth: {},
        cases: {},
        doctorList:{},
        labList:{},
        adminList:{}
    },
    lab:{
        auth: {},
        cases: {},
        profile:{}
    },

    inspector:{
        auth: {},
        cases: {},
        profile:{}
    },

    cases: [],
    auth:{},
    postcode:{},
    filterCaseStatus:"Draft",
    attachments:[]
}

const actions = {

    setfilterCaseStatus:({
        commit,
        state
    }, payload) => {
        commit('SET_FILTER_CASE_STATUS', payload);
    },

    doctorAuth: ({
        commit,
        state
    }, payload) => {
        commit('SET_DOCTOR_AUTH', payload);
    },
    setDoctorInfo: ({
        commit,
        state
    }, payload) => {
        commit('SET_DOCTOR_INFO', payload);
    },
    createDraftCase: ({
        commit,
        state
    }, payload) => {
        commit('CREATE_DRAFT_CASE', payload);
    },

    updateDraftCase: ({
        commit,
        state
    }, payload) => {
        commit('UPDATE_DRAFT_CASE', payload);
    },

    setAllCases: ({
        commit,
        state
    }, payload) => {
        commit('LIST_CASES', payload)
    },

    addNewCase: ({commit, state}, payload)=>{
        commit('ADD_NEW_CASE', payload)
    },



    removeDoctorDraftImage: ({
        commit,
        state
    }, payload) => {
        commit('REMOVE_DOCTOR_DRAFT_IMAGE', payload);
    },
    setAuth({commit,state},payload ) {
        commit('SET_AUTH', payload)
    },
    logout({
        commit,
        state
    }) {
        commit('LOGOUT')
    },

    setAdminCasesList: ({
        commit,
        state
    }, payload) => {
        commit('SET_ADMIN_CASES_LIST', payload);
    },

    setLabCasesList: ({
        commit,
        state
    }, payload) => {
        commit('SET_LAB_CASES_LIST', payload);
    },

    setInspectorCasesList: ({
        commit,
        state
    }, payload) => {
        commit('SET_INSPECTOR_CASES_LIST', payload);
    },

    getCaseInfo: ({
        commit,
        state
    }, payload) => {
        commit('GET_CASE_INFO', payload);
    },

    setListDoctor: ({commit, state},payload) => {
        commit('SET_LIST_DOCTOR', payload)
    },

    setListAdmin: ({commit, state},payload) => {
        commit('SET_LIST_ADMIN', payload)
    },

    setListLab: ({commit, state},payload) => {
        commit('SET_LIST_LAB', payload)
    },

    setListInspector: ({commit, state},payload) => {
        console.log(payload);
        commit('SET_LIST_INSPECTOR', payload)
    },

    setPostCode: ({commit, state},payload) => {
        commit('SET_POSTCODE', payload)
    }

    ,setLabProfile:({commit, state},payload) => {
        commit('LAB_PROFILE', payload)
    },

    //-----------------
    updateCasePatientImg: async ({commit, state},payload) => {
        //return commit('UPDATE_CASE_PATIENT_IMG', payload)
       //
    }

}

const mutations = {
    UPDATE_CASE_PATIENT_IMG: async (state, payload) => {
//


    },

    SET_POSTCODE: (state, payload) => {
        state.postcode = payload;
    },
    //doctor
    SET_DOCTOR_AUTH: (state, payload) => {
        state.doctor.auth = {
            _id: payload._id,
            token: payload.token,
        }
    },
    SET_DOCTOR_INFO: (state, payload) => {
        state.doctor.info = {
            ...payload
        }
    },
    CREATE_DRAFT_CASE: (state, payload) => {
        state.doctor.draft = payload;
    },

    UPDATE_DRAFT_CASE: (state, payload) => {
        state.doctor.draft = payload;
    },

    REMOVE_DOCTOR_DRAFT_IMAGE: (state, payload) => {
        state.doctor.draft.picOfPatient[payload.id] = "";
    },
    LIST_CASES: (state, payload) => {

        state.cases = payload;
    },
    ADD_NEW_CASE: (state, payload) => {
        state.cases.push(payload);
    },
    // admin
    SET_ADMIN_CASES_LIST: (state, payload) => {
        state.cases = payload;
    },

    GET_CASE_INFO: (state, payload) => {
        //state.cases
    },

    SET_AUTH: (state,payload) => {
        state.auth = payload
    },

    LAB_PROFILE: (state,payload) => {
        state.lab.profile = payload

    },
    SET_FILTER_CASE_STATUS: (state,payload) => {
        state.filterCaseStatus = payload
    },

    LOGOUT: (state) => {
        state.auth = {};
            state.info = {};
            state.cases = {};
            state.doctor= {
                auth: {},
                cases: {},
                info: {},
                draft: {}
            };
            state.lab={
                auth: {},
                cases: {},
            }
    },

    SET_LIST_DOCTOR: (state, payload) => {
        state.admin.doctorList = payload;
    },

    SET_LIST_ADMIN: (state, payload) => {
        state.admin.adminList = payload;
    },

    SET_LIST_LAB: (state, payload) => {
        state.admin.labList = payload;
    },

    SET_LIST_INSPECTOR: (state, payload) => {
        //console.log(payload);
        state.admin.inspectorList = payload;
        //console.log(state.admin.inspectorList);
    },

    SET_LAB_CASES_LIST: (state, payload) => {
        state.cases = payload;
    },

    SET_INSPECTOR_CASES_LIST: (state, payload) => {
        state.cases = payload;
    },

}

const getters = {

    getDoctorList(state){
        return state.admin.doctorList;
    },


    getAdminList(state){
        return state.admin.adminList;
    },

    getLabList(state){
        return state.admin.labList;
    },


    getInspectorList(state){
        return state.admin.inspectorList;
    },

    getAuth(state) {
        return state.auth;
    },
    getDoctorInfo(state) {
        return state.doctor.info;
    },

    getAdminDoctorInfo(state){
        //
    },
    // getDoctorDraft(){
    //     return state.doctor.draft;
    // },
    getDraft(state) {
        return state.doctor.draft;
    },
    // admin
    adminCaseList(state) {
        return state.cases;
    },
    getLabProfile(state){
        return state.lab.profile;
    },
    listSubmitted(state) {
        //console.log("Submitted")
        //console.log(state.cases);
        return  _.filter(state.cases, (item) => {
            //console.log(item);
            let status = "Draft";
                if (typeof item.caseStatus !== "undefined") {
                  status = item.caseStatus.stateStatus;
                }
            return status == "Submitted";// "RecievedNewCase";
        });


    },

    listApproved(state){
        return _.filter(state.cases, (item) => {
            let status = "Draft";
                if (typeof item.caseStatus !== "undefined") {
                  status = item.caseStatus.stateStatus;
                }
            return status == "Approved";
        });
    },

    listDraft(state) {
        //return state.cases.filters(item => {
        //item.caseStatus.stateStatus == "Draft";
        return _.filter(state.cases, (item) => {
            let status = "Draft";
                if (typeof item.caseStatus !== "undefined") {
                  status = item.caseStatus.stateStatus;
                }
            return status == "Draft";
        });
        //})
    },

    listByStatus(state) {
        //return state.cases.filters(item => {
        //item.caseStatus.stateStatus == "Draft";
        return _.filter(state.cases, (item) => {
            let status = "Draft";
                if (typeof item.caseStatus !== "undefined") {
                  status = item.caseStatus.stateStatus;
                }
            return status == state.filterCaseStatus;
        });
        //})
    },

    allCases(state) {
        //console.log(state.cases)
        return state.cases
    },
    getCaseInfo(state){
        return (caseNo) => {
            console.log(caseNo)
            return [state.cases].filter((item)=>{
                 console.log(item)
                 return item.caseNo == caseNo;
                // return (item,caseNo) => {
                //     console.log(caseNo);
                //     console.log(item);
                //     if(item.caseNo == caseNo){
                //         return item
                //     }}
            })
        }
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}